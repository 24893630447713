import { forEach, intersection, isEmpty, split } from 'lodash';
import { ParsedQuery } from 'query-string';
import { matchesRegex } from '../../core/validation/validation';
import Value from '../types/canonical/Value';

export const FILTER_REGEX = /^[^,]+=[^,]+(,[^,]+)*$/;

export function filterValidator(name: string, example: string) {
  return matchesRegex(FILTER_REGEX, name, example);
}

export function parseFilter(filter: string | undefined) {
  const [name, value] = split(filter, '=').map((value_) => value_.trim());

  if (!isEmpty(name)) {
    const values = split(value, ',').map((value_) => value_.trim());
    return { [name]: values };
  }
  return {};
}

export type ObjectProperties = {
  [key: string]: string | string[] | undefined;
};

export function getProperties(object: any): ObjectProperties {
  if (!object) {
    return {};
  }

  const properties: ObjectProperties = {};
  for (const [key, value] of Object.entries(object)) {
    if (!properties[key] && value) {
      properties[key] = String(value);
    }
  }

  if (!isEmpty(object.values) && object.values instanceof Array) {
    forEach(object.values, (value: Value) => {
      properties[value.name] = value.values;
    });
  }
  return properties;
}

export function matchFilter(
  filter: string | undefined,
  properties: ObjectProperties
) {
  if (!filter) {
    return true;
  }

  const parsedFilter = parseFilter(filter);

  for (const [key, values] of Object.entries(parsedFilter)) {
    const value = properties[key];
    if (value instanceof Array && intersection(value, values).length > 0) {
      return true;
    } else if (value && values.includes(value as string)) {
      return true;
    }
  }

  return false;
}

export function getFilterParam(
  name: string,
  queryParams: ParsedQuery<string>
): string[] {
  const param = queryParams[name];
  if (param) {
    if (!Array.isArray(param)) {
      return [param];
    }
    return param as string[];
  }
  return [];
}
