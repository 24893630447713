import { parse } from 'query-string';
import { useLocation } from 'react-router';
import { MainTabParams, toMainTabParams } from './MainTabs';

export function useMainTabParams(): MainTabParams {
  const location = useLocation();
  const params = parse(location.search);

  let mainTab;
  if (Array.isArray(params.mainTab)) {
    mainTab = params.mainTab[0];
  } else {
    mainTab = params.mainTab;
  }
  return toMainTabParams(mainTab);
}
