import { Col, Row } from 'reactstrap';
import LinkLabel from '../../../LinkLabel/LinkLabel';

export default function Footer() {
  return (
    <div id="footer">
      <Row className="footer-container">
        <Col
          xs={12}
          md={12}
          className="footer-content-extra text-center p-0"
        >
          <LinkLabel
            labelKey="FOOTER.COPYRIGHT.LABEL"
            urlKey="FOOTER.COPYRIGHT.URL"
            namespace="footer"
          />
        </Col>
      </Row>
    </div>
  );
}
