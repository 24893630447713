import { getText } from '../../../i18n/Text';
import Widget from '../../Widget';

type Props = {
  widget: Widget;
};

export function WidgetTitle({ widget }: Readonly<Props>) {
  const label = getText(widget.labels);

  if (!label) {
    return null;
  }

  return (
    <h2 className="details-main-name d-flex flex-column flex-grow-1 mt-2">
      {label}
    </h2>
  );
}
