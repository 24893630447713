import { MainTabParams } from '../../components/AppFrame/components/TopBar/components/MainTabs/MainTabs';
import { getYearService } from '../../years/YearService';

export type GroupDetailPathParams = {
  code: string;
};

export type GroupDetailQueryParams = {
  year: string;
  tab?: string;
} & MainTabParams;

export function defaultGroupDetailQueryParams(): GroupDetailQueryParams {
  return {
    year: getYearService().getCurrentYear().externalId,
    tab: 'info',
    mainTab: 'group'
  };
}
