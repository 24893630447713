import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Table } from 'reactstrap';
import { Loader } from '../../../components';
import { getText } from '../../../i18n/Text';
import { moduleDetailUrl } from '../../../modules/ModuleDetail/ModuleDetail';
import { getPeriodService } from '../../../periods/PeriodService';
import { displayName, ReferenceType } from '../../../references/Reference';
import { useReferences } from '../../../references/useReferences';
import { getCredits } from '../../../types/canonical/Credits';
import { getDefaultCode } from '../../../types/canonical/Data';
import Group from '../../../types/canonical/Group';
import { getPeriodsDisplayName } from '../../../types/canonical/Offering';
import {
  loadModuleRows,
  ModuleRow,
  sortModules
} from '../../../types/SimpleModule';
import { WidgetField } from '../../../widgets/Widget';
import { CustomItem } from '../../../widgets/WidgetItem/components/CustomItem';
import { StructureStoreReturnType } from '../../GroupStructure/structureStore';

type Props = {
  group: Group;
  year: string;
  fields: WidgetField[];
};

export function GroupModulesTable({ group, year, fields }: Readonly<Props>) {
  const { t } = useTranslation('module');
  const { references: timeBlocks } = useReferences(
    ReferenceType.TIME_BLOCK,
    year
  );
  const { references: phases } = useReferences(ReferenceType.PHASE, year);
  const periods = getPeriodService().getPeriods(year);
  const ensuredId = getDefaultCode(group);

  const groupSettings = useSelector(
    (state: StructureStoreReturnType) => state.groups[ensuredId]
  );

  const state = useQuery(
    ['groupModules', { groupUid: group.uid, yearId: group.year.id }],
    () => {
      if (groupSettings.isExpanded) {
        return loadModuleRows(group);
      }
      return Promise.resolve([]);
    }
  );

  const headers = _(fields)
    .filter({ visible: true })
    .orderBy(['sequence', 'name'])
    .take(3)
    .value();

  function moduleLink(row: ModuleRow) {
    if (row.module && row.module.data) {
      return (
        <a href={moduleDetailUrl(row.module.data)}>{row.module.data.code}</a>
      );
    }
    return null;
  }

  function moduleCell(row: ModuleRow, header: WidgetField) {
    switch (header.name) {
      case 'credits':
        return `${getCredits(row.module?.data.credits)}`;
      case 'description':
        return row.structure.description || '';
      case 'offerings':
        return getPeriodsDisplayName(row.structure.offerings, timeBlocks).join(
          ', '
        );
      case 'phase':
        return displayName(row.structure.phase, phases, row.structure.phase);
      default:
        return (
          <CustomItem
            field={header}
            values={row.structure.values}
            year={year}
            type="STRUCTURE"
          />
        );
    }
  }

  return (
    <Loader state={state}>
      {(rows) => (
        <Table
          responsive
          striped
          size="sm"
          className="mb-0"
        >
          <thead>
            <tr>
              <th>{t('COLUMNS.CODE')}</th>
              <th>{t('COLUMNS.NAME')}</th>
              {headers.map((header, index) => (
                <th key={`header-${index}`}>
                  {getText(header.labels, header.name)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortModules(rows, phases, periods, timeBlocks).map(
              (row, rowIndex) => (
                <tr
                  key={rowIndex}
                  className="text-truncate"
                >
                  <td>{moduleLink(row)}</td>
                  <td>{getText(row.module?.data.names)}</td>
                  {headers.map((header, headerIndex) => (
                    <td key={`row-${rowIndex}-${headerIndex}`}>
                      {moduleCell(row, header)}
                    </td>
                  ))}
                </tr>
              )
            )}
          </tbody>
        </Table>
      )}
    </Loader>
  );
}
