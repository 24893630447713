import { Col } from 'reactstrap';
import { getText } from '../../../i18n/Text';
import Product from '../../../types/Product';
import Widget, { getFields } from '../../Widget';
import { WidgetItem } from '../../WidgetItem/WidgetItem';

type Props = {
  widget: Widget;
  product: Product;
  expanded: boolean;
};

export function SidebarWidget({ widget, product, expanded }: Readonly<Props>) {
  return (
    <Col className="details-content p-0 mb-5">
      <Col className="details-header px-4 py-3">
        {getText(widget.labels, widget.name)}
      </Col>
      <Col className="px-4 py-2">
        {getFields(widget).map((field, index) => (
          <WidgetItem
            key={`field-${index}`}
            field={field}
            type={widget.type}
            product={product}
            expanded={expanded}
          />
        ))}
      </Col>
    </Col>
  );
}
