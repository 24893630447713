import { get, makeResource } from '@42.nl/spring-connect';
import _, { capitalize, find, isEmpty } from 'lodash';
import { FILTER_REGEX } from '../filters/Filters';
import { getText, Text } from '../i18n/Text';
import Reference, { displayName } from '../references/Reference';
import Value from '../types/canonical/Value';
import { ProductType } from '../types/Product';

const baseUrl = '/api/widgets';

export default class Widget extends makeResource<Widget>(baseUrl) {
  id!: number;
  entityType!: ProductType;
  name!: string;
  filter?: string;
  sequence!: number;
  visible!: boolean;
  showOnPrint!: boolean;
  type!: WidgetType;
  labels!: Text[];
  fields!: WidgetField[];

  static getWidgetsForTab(tabId: number): Promise<Widget[]> {
    return get(baseUrl, { tabId: tabId });
  }
}

export class WidgetField {
  id?: number;
  type!: FieldType;
  name!: string;
  filter?: string;
  format?: string;
  referenceType?: string;
  sequence!: number;
  visible!: boolean;
  hideEmpty!: boolean;
  admin!: boolean;
  labels!: Text[];
  tooltips!: Text[];

  hasFilter() {
    return (
      this.filter && !isEmpty(this.filter) && FILTER_REGEX.test(this.filter)
    );
  }
}

export function getFields(widget: Widget) {
  return _(widget.fields)
    .filter('visible')
    .sortBy(['sequence', 'name', 'id'])
    .value();
}

export function getLabel(field: WidgetField) {
  return getText(field.labels, capitalize(field.name));
}

export function getValue(
  field: WidgetField,
  values: Value[],
  references: Reference[]
) {
  const value = find(values, { name: field.name });
  if (!value) {
    return '';
  }

  if (!isEmpty(value.description)) {
    return value.description;
  }

  return translate(field, value.values, references);
}

export function translate(
  field: WidgetField,
  values: any[],
  references: Reference[]
) {
  return values
    .map((value) => {
      if (value instanceof Date) {
        return value.toLocaleDateString();
      }

      return displayName(value, references, value);
    })
    .sort()
    .join(', ');
}

export type WidgetType =
  | 'APPRAISALS'
  | 'CONTAINER'
  | 'SIDEBAR'
  | 'STRUCTURE'
  | 'BUTTONS';

export type FieldType =
  | 'ADDITIONAL'
  | 'ASSESSMENTS'
  | 'CUSTOM'
  | 'DESCRIPTION'
  | 'GROUPS'
  | 'LICENSES'
  | 'LINKS'
  | 'METHODS'
  | 'OBJECTIVES'
  | 'PERIODS'
  | 'PERIODS_VALUE'
  | 'RELATION'
  | 'RULES'
  | 'SUBJECTS'
  | 'URL'
  | 'VALUE';
