import { get, join, transform } from 'lodash';
import { getProperties, ObjectProperties } from '../filters/Filters';
import { groupDetailUrl } from '../groups/GroupDetail/GroupDetail';
import { replaceVariables } from '../i18n/Interpolation';
import { moduleDetailUrl } from '../modules/ModuleDetail/ModuleDetail';
import { qualificationDetailUrl } from '../qualifications/QualificationDetail/QualificationDetail';
import { studyDetailUrl } from '../studies/StudyDetail/StudyDetail';
import Data from './canonical/Data';

export default interface Product {
  id: number;
  productType: ProductType;
  data: Data;
}

export enum ProductTypeEnum {
  FACULTY = 'FACULTY',
  QUALIFICATION = 'QUALIFICATION',
  SPECIFICATION = 'SPECIFICATION',
  STUDY = 'STUDY',
  GROUP = 'GROUP',
  MODULE = 'MODULE'
}

export const PRODUCT_TYPES: string[] = Object.values(ProductTypeEnum).sort();
export type ProductType = (typeof PRODUCT_TYPES)[number];

export type ProductListQueryParams = {
  year: string;
  code?: string[];
  text?: string;
};

export function getProductProperties(product: any): ObjectProperties {
  const properties = getProperties(product);

  properties.year = get(product, 'year.externalId');
  properties.type = get(product, 'type.externalId');
  properties.language = get(product, 'language.externalId');

  for (const [key, value] of Object.entries(product)) {
    if (!properties[key] && value) {
      properties[key] = String(value);
    }
  }
  return properties;
}

export function getTemplate(template: string, data: Data) {
  const variables = transform(
    data.additional?.values || [],
    (result: { [key: string]: string }, value) => {
      result[`${value.name}`] = join(value.values);
    },
    {}
  );

  variables.id = data.id || '';
  variables.code = data.code?.toUpperCase() || '';
  variables.year = data.year.id || '';
  variables.key = data.key?.toString() || '';

  return replaceVariables(template, variables);
}

export function getProductUrl({ data, productType }: Product): string {
  switch (productType) {
    case ProductTypeEnum.QUALIFICATION:
      return qualificationDetailUrl(data);
    case ProductTypeEnum.STUDY:
      return studyDetailUrl(data);
    case ProductTypeEnum.GROUP:
      return groupDetailUrl(data);
    case ProductTypeEnum.MODULE:
      return moduleDetailUrl(data);
    default:
      return '';
  }
}
