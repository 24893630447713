import { Button } from '@42.nl/ui';
import { useAuthentication } from '../../authentication/useAuthentication';

type Props = {
  type?: 'button' | 'submit';
  label: string;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
};

export function AdminButton({
  type = 'button',
  label,
  className,
  disabled,
  onClick
}: Readonly<Props>) {
  const auth = useAuthentication();
  const isAdmin = auth?.currentUser?.roles.includes('ADMIN');

  return isAdmin ? (
    <Button
      type={type}
      onClick={onClick}
      className={className}
      disabled={disabled}
    >
      {label}
    </Button>
  ) : null;
}
