import { useEffect, useState } from 'react';
import _, { isEmpty, transform } from 'lodash';
import { useQuery } from 'react-query';
import { getText } from '../../i18n/Text';
import Data from '../../types/canonical/Data';
import Product, { ProductType, getTemplate } from '../../types/Product';
import Widget, { WidgetField, getFields } from '../Widget';

type Props = {
  product: Product;
  children?: React.ReactNode;
};

export function WidgetButtons({ product, children }: Readonly<Props>) {
  const state = useQuery(
    ['linkWidgets', { productType: product.productType }],
    () => loadWidgets(product.productType)
  );

  const [buttons, setButtons] = useState<WidgetButton[]>([]);

  useEffect(() => {
    if (state.data) {
      const transformedButtons = transform(
        state.data,
        (result, widget) => {
          result.push(...getButtons(widget, product.data));
        },
        new Array<WidgetButton>()
      );
      setButtons(transformedButtons);
    }
  }, [state.data, product]);

  return (
    <span className="float-end">
      {buttons.map((button, index) => (
        <span
          key={index}
          className="button me-1"
        >
          <a
            className="btn btn-primary"
            href={button.url}
            target="_blank"
            rel="noreferrer"
            role="button"
            aria-pressed="true"
          >
            {button.name}
          </a>
        </span>
      ))}

      {children}
    </span>
  );
}

async function loadWidgets(productType: ProductType): Promise<Widget[]> {
  const widgets = await Widget.list();
  return _(widgets)
    .filter((widget) => widget.entityType === productType)
    .filter((widget) => widget.type === 'BUTTONS')
    .filter('visible')
    .sortBy('sequence', 'id')
    .value();
}

class WidgetButton {
  name?: string;
  url: string;

  constructor(field: WidgetField, data: Data) {
    this.name = getText(field.labels, '?');
    this.url = getTemplate(field.name, data);
  }
}

function getButtons(widget: Widget, data: Data): WidgetButton[] {
  return getFields(widget)
    .filter((field) => field.type === 'URL')
    .map((field) => {
      return new WidgetButton(field, data);
    })
    .filter((button) => !isEmpty(button.url));
}
