import { matchFilter } from '../filters/Filters';
import Data from '../types/canonical/Data';
import { ProductType, getProductProperties } from '../types/Product';
import Tab from './Tab';

type ProductProps = {
  productType: ProductType;
  data?: Data;
};

class TabService {
  private tabs: Tab[] | null = null;
  constructor() {
    this.getAll = this.getAll.bind(this);
  }

  isActive(
    tab: Tab,
    name: string | undefined,
    product: ProductProps | undefined
  ): boolean {
    if (name === tab.name) {
      return true;
    }
    if (product && tab.entityType === product.productType) {
      if (!tab.hasFilter()) {
        return true;
      }

      const properties = getProductProperties(product);
      return matchFilter(tab.filter, properties);
    }
    return false;
  }

  getActive(tabs: Tab[], name: string | undefined): Tab | undefined {
    let tab = tabs.find((tab_) => tab_.name === name);
    if (!tab && tabs.length) {
      tab = tabs[0];
    }
    return tab;
  }

  async getAll(): Promise<Tab[]> {
    if (!this.tabs) {
      this.tabs = await Tab.findAll();
    }
    return this.tabs || [];
  }

  async forceTabsRefresh() {
    this.tabs = await Tab.findAll();
  }

  async getMainTab(
    name: string | undefined,
    product: ProductProps
  ): Promise<Tab | undefined> {
    const tabs = await this.getAll();
    return tabs.find(
      (tab) => this.isActive(tab, name, product) && isParent(tab)
    );
  }

  async getMainTabs(): Promise<Tab[]> {
    const tabs = await this.getAll();
    return tabs.filter(isParent);
  }

  async getSubTabs(parent: Tab | undefined): Promise<Tab[]> {
    if (parent === undefined) {
      return Promise.resolve([]);
    }

    return this.getAll().then((tabs) => {
      return tabs.filter(
        (tab) =>
          tab.parentTabs?.length &&
          tab.parentTabs.map((tab_) => tab_.id).includes(parent.id)
      );
    });
  }
}

function isParent(tab: Tab) {
  return tab.parentTabs === undefined || !tab.parentTabs.length;
}

const instance = new TabService();

export function getTabService(): TabService {
  return instance;
}
