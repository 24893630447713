import { useState } from 'react';
import { urlBuilder } from '@42.nl/react-url';
import { isEmpty, split } from 'lodash';
import { parse } from 'query-string';
import { useQuery } from 'react-query';
import { NavLink, useLocation } from 'react-router-dom';
import { Nav } from 'reactstrap';
import { getText } from '../../../../../../i18n/Text';
import Tab from '../../../../../../tabs/Tab';
import { getTabService } from '../../../../../../tabs/TabService';
import Loader from '../../../../../Loader/Loader';
import { useMainTabParams } from './useMainTabParams';

type MainTabsProps = {
  closeMobileMenu: () => void;
};

export default function MainTabs({ closeMobileMenu }: MainTabsProps) {
  const mainTabParams = useMainTabParams();
  const [activeTab, setActiveTab] = useState<Tab>();

  const state = useQuery('mainTabs', () => {
    const name = mainTabParams.mainTab;
    return getTabs().then((tabs) => {
      const startTab = findActive(tabs, name);
      setActiveTab(startTab);
      return tabs;
    });
  });

  function onTab(tab: Tab) {
    setActiveTab(tab);
    closeMobileMenu();
  }

  return (
    <Loader state={state}>
      {(tabs) => (
        <Nav
          navbar
          className="main-nav flex-row"
        >
          {tabs?.map((tab, index) => (
            <MainTab
              tab={tab}
              active={tab.id === activeTab?.id}
              key={index}
              onClick={() => onTab(tab)}
            />
          ))}
        </Nav>
      )}
    </Loader>
  );
}

async function getTabs(): Promise<Tab[]> {
  return getTabService().getMainTabs();
}

function findActive(tabs: Tab[], name: string | undefined) {
  return tabs.find((tab) => {
    if (name) {
      return getTabService().isActive(tab, name, undefined);
    }

    return isUrlIncludedInPath(tab);
  });
}

function isUrlIncludedInPath(tab: Tab) {
  if (!tab) {
    return false;
  }

  const currentPath = window.location.pathname;
  const url = tab.getUrl();

  return url && currentPath.includes(url);
}

type MainTabProps = {
  tab: Tab;
  active: boolean;
  onClick: () => void;
};

function MainTab({ tab, active, onClick }: MainTabProps) {
  const baseUrl = tab.getUrl() || '';
  if (isEmpty(baseUrl)) {
    return null;
  }

  return (
    <EntityTab
      tab={tab}
      active={active}
      baseUrl={baseUrl}
      onClick={onClick}
    />
  );
}

type EntityTabProps = {
  tab: Tab;
  active: boolean;
  baseUrl: string;
  onClick: () => void;
};

function EntityTab({ tab, active, baseUrl, onClick }: EntityTabProps) {
  const location = useLocation();

  function getUrl() {
    const searchParams = parse(location.search);
    const year = Array.isArray(searchParams.year)
      ? searchParams.year[0]
      : searchParams.year;
    const params = { ...toQueryParams(tab, year) };

    return urlBuilder({
      url: baseUrl,
      pathParams: params,
      queryParams: params
    });
  }

  return (
    <NavLink
      to={getUrl()}
      onClick={onClick}
      className={() => (active ? 'active' : '')}
      replace
    >
      {getText(tab.labels, tab.name)}
    </NavLink>
  );
}

export function toQueryParams(tab: Tab | undefined, year: string | null) {
  const params = {
    mainTab: tab?.name || '',
    year: year || ''
  };

  if (tab && tab.hasFilter()) {
    const [name, value] = split(tab.filter, '=').map((value_) => value_.trim());

    if (!isEmpty(name)) {
      const values = split(value, ',').map((value_) => value_.trim());
      return { [name]: values, ...params };
    }
  }

  return params;
}

export type MainTabParams = {
  mainTab?: string;
};

export function toMainTabParams(name: string | null): MainTabParams {
  return { mainTab: name || undefined };
}

export const defaultMainTabParams = {
  mainTab: ''
};
