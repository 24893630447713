import { useEffect, useState } from 'react';
import { addSuccess } from '@42.nl/react-flash-messages';
import { Url, urlBuilder, useQueryParams } from '@42.nl/react-url/lib';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Provider } from 'react-redux';
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';
import { AppFrame, Loader } from '../../components';
import { AdminButton } from '../../components/AdminButton/AdminButton';
import { toQueryParams } from '../../components/AppFrame/components/TopBar/components/MainTabs/MainTabs';
import { useMainTabParams } from '../../components/AppFrame/components/TopBar/components/MainTabs/useMainTabParams';
import { PrintButton } from '../../components/Print/PrintButton';
import { structureStore } from '../../groups/GroupStructure/structureStore';
import { LanguageLabel } from '../../i18n';
import { ProductPrint } from '../../product/ProductPrint';
import SubTabsNav from '../../tabs/SubTabsNav';
import Tab from '../../tabs/Tab';
import { getTabService } from '../../tabs/TabService';
import AcademicYear from '../../types/AcademicYear';
import Qualification from '../../types/canonical/Qualification';
import SimpleQualification from '../../types/SimpleQualification';
import { WidgetButtons } from '../../widgets/WidgetDetail/WidgetButtons';
import { WidgetDetail } from '../../widgets/WidgetDetail/WidgetDetail';
import YearDropdown from '../../years/components/YearDropdown/YearDropdown';
import {
  QUALIFICATION_APP_URL,
  toQualificationsApp
} from '../QualificationLinks';
import {
  QualificationDetailPathParams,
  QualificationDetailQueryParams,
  defaultQualificationDetailQueryParams
} from './QualificationDetailQueryParams';

export async function loadQualification(
  code: string,
  queryParams: QualificationDetailQueryParams
) {
  return SimpleQualification.one(code, queryParams);
}

export default function QualificationDetail() {
  const navigate = useNavigate();
  const location = useLocation();

  const mainTabParams = useMainTabParams();
  const [mainTab, setMainTab] = useState<Tab>();
  const [subTabs, setSubTabs] = useState<Tab[]>([]);
  const [activeTab, setActiveTab] = useState<Tab>();

  function toggle(tab: Tab) {
    const url = toQualificationDetail(
      {
        code: `${state.data?.data.code}`
      },
      {
        year: `${state.data?.data.year.id}`,
        tab: `${tab.name}`,
        ...mainTabParams
      }
    );

    navigate(url, { replace: true });
  }

  const queryParams = useQueryParams<QualificationDetailQueryParams>({
    location,
    defaultQueryParams: defaultQualificationDetailQueryParams(),
    debugName: 'QualificationDetail'
  });

  const { code = '' } = useParams<QualificationDetailPathParams>();
  const { t } = useTranslation(['translation', 'qualification', 'import']);

  const state = useQuery(['qualifications', { code, queryParams }], () =>
    loadQualification(code, queryParams)
  );

  useEffect(() => {
    if (state.data) {
      getTabService()
        .getMainTab(queryParams.tab, state.data)
        .then((mainTab_) => {
          setMainTab(mainTab_);
        });
    }
  }, [queryParams.tab, state.data]);

  useEffect(() => {
    if (mainTab) {
      getTabService()
        .getSubTabs(mainTab)
        .then((tabs) => {
          setSubTabs(tabs);

          const tab = getTabService().getActive(tabs, queryParams.tab);
          setActiveTab(tab);
        });
    }
  }, [mainTab, queryParams.tab]);

  const [years, setYears] = useState<AcademicYear[]>();
  useEffect(() => {
    async function fetchYears() {
      if (state.data && state.data.data.code) {
        const data = await SimpleQualification.years(state.data.data.code);
        setYears(data);
      }
    }

    if (!years) {
      fetchYears();
    }
  }, [state.data, years]);

  function filterChanged(name: string, value: string | number) {
    navigate(
      toQualificationDetail({ code }, { ...queryParams, [name]: value }),
      {
        replace: true
      }
    );
    window.location.reload();
  }

  async function importQualification(qualification: SimpleQualification) {
    try {
      await SimpleQualification.import(qualification);
      addSuccess({
        text: t('import:STARTED_MESSAGE')
      });
    } catch (error) {
      /* empty */
    }
  }

  return (
    <Loader
      state={state}
      isEmpty={(data) => data?.id === undefined}
      emptyContent={() => (
        <Navigate
          to={toQualificationsApp(mainTabParams)}
          replace
        />
      )}
    >
      {(qualification) => (
        <AppFrame
          breadcrumbs={
            <Breadcrumb>
              <BreadcrumbItem>
                <Link
                  to={toQualificationsApp({
                    ...toQueryParams(mainTab, qualification.data.year.id),
                    year: queryParams.year
                  })}
                >
                  {t('qualification:QUALIFICATIONS')}
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>{qualification.data.code}</BreadcrumbItem>
            </Breadcrumb>
          }
        >
          <Row className="justify-content-center">
            <Col
              md={12}
              lg={12}
              xs={12}
              className="details-main me-auto"
            >
              <Provider store={structureStore}>
                <h1 className="details-main-title">
                  <span className="pe-1">({qualification.data.code})</span>
                  <LanguageLabel names={qualification.data.names} />
                </h1>

                <Row>
                  <Col
                    m={12}
                    md={12}
                    lg={12}
                    xs={12}
                  >
                    <YearDropdown
                      externalId={queryParams.year}
                      initYears={years}
                      className="mb-4 d-inline"
                      onChange={filterChanged}
                    />
                    <WidgetButtons product={qualification}>
                      <AdminButton
                        className="me-1"
                        label={t('import:BUTTON')}
                        onClick={() => importQualification(qualification)}
                      />
                      <PrintButton>
                        <ProductPrint
                          tabs={subTabs}
                          product={qualification}
                        />
                      </PrintButton>
                    </WidgetButtons>
                  </Col>
                </Row>

                <SubTabsNav
                  subTabs={subTabs}
                  detailItem={state.data}
                  toggle={toggle}
                  tab={activeTab?.name}
                />

                <TabContent activeTab={activeTab?.name}>
                  {subTabs.map((subTab) => (
                    <TabPane
                      tabId={subTab.name}
                      key={subTab.id}
                    >
                      <WidgetDetail
                        tabId={subTab.id}
                        product={qualification}
                        expanded={false}
                      />
                    </TabPane>
                  ))}
                </TabContent>
              </Provider>
            </Col>
          </Row>
        </AppFrame>
      )}
    </Loader>
  );
}

export const QUALIFICATION_DETAIL_URL = `${QUALIFICATION_APP_URL}/:code`;

function toQualificationDetail(
  pathParams: QualificationDetailPathParams,
  queryParams: QualificationDetailQueryParams
): Url {
  return urlBuilder({
    url: QUALIFICATION_DETAIL_URL,
    pathParams: pathParams,
    queryParams: queryParams
  });
}

export function qualificationDetailUrl({
  id,
  uid,
  code,
  year,
  mainTab
}: {
  mainTab?: string;
} & Qualification) {
  return toQualificationDetail(
    {
      code: code || id || uid
    },
    {
      year: year.id,
      mainTab
    }
  );
}
