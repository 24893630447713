import { MainTabParams } from '../../components/AppFrame/components/TopBar/components/MainTabs/MainTabs';
import { getYearService } from '../../years/YearService';

export type ModuleDetailPathParams = {
  code: string;
};

export type ModuleDetailQueryParams = {
  year: string;
  tab?: string;
} & MainTabParams;

export function defaultModuleDetailQueryParams(): ModuleDetailQueryParams {
  return {
    year: getYearService().getCurrentYear().externalId,
    tab: 'info',
    mainTab: 'module'
  };
}
