import { isEmpty } from 'lodash';
import { getText } from '../../../i18n/Text';
import Objective from '../../../types/canonical/Objective';
import { WidgetField, WidgetType } from '../../Widget';
import { FieldSection } from './FieldSection';

type Props = {
  field: WidgetField;
  type: WidgetType;
  objectives: Objective[];
};

export function ObjectivesItem({ field, type, objectives }: Readonly<Props>) {
  const filtered = objectives
    .map((subject) => getText(subject.names))
    .filter((name) => !!name);

  return (
    <FieldSection
      field={field}
      type={type}
      isEmpty={isEmpty(filtered)}
    >
      {() => (
        <ul className={type === 'SIDEBAR' ? 'list-unstyled' : ''}>
          {filtered.map((name, index) => (
            <li key={index}>{name}</li>
          ))}
        </ul>
      )}
    </FieldSection>
  );
}
