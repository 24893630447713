import { InfoTooltip } from '@42.nl/ui';
import { isEmpty } from 'lodash';
import { getText } from '../../../i18n/Text';
import Data from '../../../types/canonical/Data';
import Subject from '../../../types/canonical/Subject';
import { WidgetField, WidgetType } from '../../Widget';
import { FieldSection } from './FieldSection';

type Props = {
  field: WidgetField;
  type: WidgetType;
  data: Data;
};

export function SubjectsItem({ field, type, data }: Readonly<Props>) {
  let subjects = data.subjects || [];

  const subjectType = field.referenceType;
  if (subjectType) {
    subjects = subjects.filter(
      (subject) => subject.reference.type === subjectType
    );
  }

  return (
    <FieldSection
      field={field}
      type={type}
      isEmpty={isEmpty(subjects)}
    >
      {() => (
        <ul className={type === 'SIDEBAR' ? 'list-unstyled' : ''}>
          {subjects?.map((subject, index) => (
            <li key={index}>
              <SubjectItem subject={subject} />
            </li>
          ))}
        </ul>
      )}
    </FieldSection>
  );
}

function SubjectItem({ subject }: { subject: Subject }) {
  const description = getText(subject.descriptions);

  return (
    <>
      {getText(subject.reference.names)}
      {description ? (
        <InfoTooltip
          tooltip={description}
          className="ms-1 text-primary"
          size={15}
        />
      ) : null}
    </>
  );
}
