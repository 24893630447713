import { useEffect, useState } from 'react';
import { addSuccess } from '@42.nl/react-flash-messages';
import { Url, urlBuilder, useQueryParams } from '@42.nl/react-url/lib';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';
import { AppFrame, Loader } from '../../components';
import { AdminButton } from '../../components/AdminButton/AdminButton';
import { toQueryParams } from '../../components/AppFrame/components/TopBar/components/MainTabs/MainTabs';
import { useMainTabParams } from '../../components/AppFrame/components/TopBar/components/MainTabs/useMainTabParams';
import { PrintButton } from '../../components/Print/PrintButton';
import { LanguageLabel } from '../../i18n';
import { ProductPrint } from '../../product/ProductPrint';
import SubTabsNav from '../../tabs/SubTabsNav';
import Tab from '../../tabs/Tab';
import { getTabService } from '../../tabs/TabService';
import AcademicYear from '../../types/AcademicYear';
import Module from '../../types/canonical/Module';
import { MODULE_APP_URL, toModulesApp } from '../../types/ModuleLinks';
import SimpleModule from '../../types/SimpleModule';
import { WidgetButtons } from '../../widgets/WidgetDetail/WidgetButtons';
import { WidgetDetail } from '../../widgets/WidgetDetail/WidgetDetail';
import YearDropdown from '../../years/components/YearDropdown/YearDropdown';
import {
  ModuleDetailPathParams,
  ModuleDetailQueryParams,
  defaultModuleDetailQueryParams
} from './ModuleDetailQueryParams';

export async function loadModule(
  code: string,
  queryParams: ModuleDetailQueryParams
) {
  return SimpleModule.one(code, queryParams);
}

export default function ModuleDetail() {
  const navigate = useNavigate();
  const location = useLocation();

  const mainTabParams = useMainTabParams();
  const [mainTab, setMainTab] = useState<Tab>();
  const [subTabs, setSubTabs] = useState<Tab[]>([]);
  const [activeTab, setActiveTab] = useState<Tab>();

  const queryParams = useQueryParams<ModuleDetailQueryParams>({
    location,
    defaultQueryParams: defaultModuleDetailQueryParams(),
    debugName: 'ModuleDetail'
  });

  const { code = '' } = useParams<ModuleDetailPathParams>();
  const { t } = useTranslation(['translation', 'module', 'import']);

  const state = useQuery(['module', { code, queryParams }], () =>
    loadModule(code, queryParams)
  );

  function toggle(tab: Tab) {
    const url = toModuleDetail(
      {
        code: `${state.data?.data.code}`
      },
      {
        year: `${state.data?.data.year.id}`,
        tab: `${tab.name}`,
        ...mainTabParams
      }
    );

    setActiveTab(tab);
    navigate(url, { replace: true });
  }

  useEffect(() => {
    if (state.data) {
      getTabService().getMainTab(queryParams.tab, state.data).then(setMainTab);
    }
  }, [queryParams.tab, state.data]);

  useEffect(() => {
    if (mainTab) {
      getTabService()
        .getSubTabs(mainTab)
        .then((tabs) => {
          setSubTabs(tabs);

          const tab = getTabService().getActive(tabs, queryParams.tab);
          setActiveTab(tab);
        });
    }
  }, [mainTab, queryParams.tab]);

  const [years, setYears] = useState<AcademicYear[]>();
  useEffect(() => {
    async function fetchYears() {
      if (state.data && state.data.data.code) {
        const data = await SimpleModule.years(state.data.data.code);
        setYears(data);
      }
    }

    if (!years) {
      fetchYears();
    }
  }, [state.data, years]);

  function filterChanged(name: string, value: string | number) {
    navigate(toModuleDetail({ code }, { ...queryParams, [name]: value }), {
      replace: true
    });
    window.location.reload();
  }

  async function importModule(module: SimpleModule) {
    try {
      await SimpleModule.import(module);
      addSuccess({
        text: t('import:STARTED_MESSAGE')
      });
    } catch (error) {
      /* empty */
    }
  }

  return (
    <Loader
      state={state}
      isEmpty={(data) => data?.id === undefined}
      emptyContent={() => (
        <Navigate
          to={toModulesApp(mainTabParams)}
          replace
        />
      )}
    >
      {(module) => (
        <AppFrame
          breadcrumbs={
            <Breadcrumb>
              <BreadcrumbItem>
                <Link
                  to={toModulesApp({
                    ...toQueryParams(mainTab, module.data.year.id),
                    year: queryParams.year
                  })}
                >
                  {t('module:MODULES')}
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {module.data.code?.toUpperCase()}
              </BreadcrumbItem>
            </Breadcrumb>
          }
        >
          <Row className="justify-content-center">
            <Col
              md={12}
              lg={12}
              xs={12}
              className="details-main me-auto"
            >
              <h1 className="details-main-title">
                <span className="pe-1">({module.data.code})</span>
                <LanguageLabel names={module.data.names} />
              </h1>

              <Row className="mb-3">
                <Col
                  m={12}
                  md={12}
                  lg={12}
                  xs={12}
                >
                  <YearDropdown
                    externalId={queryParams.year}
                    initYears={years}
                    onChange={filterChanged}
                    className="d-inline"
                  />

                  <WidgetButtons product={module}>
                    <AdminButton
                      className="me-1"
                      label={t('import:BUTTON')}
                      onClick={() => importModule(module)}
                    ></AdminButton>

                    <PrintButton>
                      <ProductPrint
                        product={module}
                        tabs={subTabs}
                      />
                    </PrintButton>
                  </WidgetButtons>
                </Col>
              </Row>

              <SubTabsNav
                subTabs={subTabs}
                detailItem={state.data}
                toggle={toggle}
                tab={activeTab?.name}
              />

              <TabContent activeTab={activeTab?.name}>
                {subTabs.map((subTab) => (
                  <TabPane
                    tabId={subTab.name}
                    key={subTab.id}
                  >
                    <WidgetDetail
                      tabId={subTab.id}
                      product={module}
                      expanded={false}
                    />
                  </TabPane>
                ))}
              </TabContent>
            </Col>
          </Row>
        </AppFrame>
      )}
    </Loader>
  );
}

export const MODULE_DETAIL_URL = `${MODULE_APP_URL}/:code`;

function toModuleDetail(
  pathParams: ModuleDetailPathParams,
  queryParams: ModuleDetailQueryParams
): Url {
  return urlBuilder({
    url: MODULE_DETAIL_URL,
    pathParams: pathParams,
    queryParams: queryParams,
    defaultQueryParams: defaultModuleDetailQueryParams()
  });
}

export function moduleDetailUrl({
  id,
  uid,
  code,
  year,
  mainTab
}: {
  mainTab?: string;
} & Module) {
  return toModuleDetail(
    {
      code: code || id || uid
    },
    {
      year: `${year.id}`,
      mainTab
    }
  );
}
