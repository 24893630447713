import { EpicTableSortDirection } from '@42.nl/ui';
import i18n, { t } from 'i18next';
import { map } from 'lodash';
import { languageCodeToQueryName } from '../../i18n/LanguageSwitcher/LanguageSwitcher';
import { ProductType } from '../../types/Product';

export class SortOption {
  column: string;
  direction: EpicTableSortDirection;
  sort: string;
  displayName: string;

  constructor(
    column: string,
    direction: EpicTableSortDirection,
    displayName: string
  ) {
    this.column = column;
    this.direction = direction;
    this.sort = `${column},${direction}`;
    this.displayName = displayName;
  }
}

interface SortOptionConfig {
  field: string;
  direction: EpicTableSortDirection;
  translationKey: string;
}

const commonSortOptions: SortOptionConfig[] = [
  {
    field: 'language',
    direction: 'ASC',
    translationKey: 'sort:OPTIONS.NAME_ASC'
  },
  {
    field: 'language',
    direction: 'DESC',
    translationKey: 'sort:OPTIONS.NAME_DESC'
  },
  { field: 'code', direction: 'ASC', translationKey: 'sort:OPTIONS.CODE_ASC' },
  { field: 'code', direction: 'DESC', translationKey: 'sort:OPTIONS.CODE_DESC' }
];

const creditsSortOptions: SortOptionConfig[] = [
  {
    field: 'credits',
    direction: 'ASC',
    translationKey: 'sort:OPTIONS.CREDITS_ASC'
  },
  {
    field: 'credits',
    direction: 'DESC',
    translationKey: 'sort:OPTIONS.CREDITS_DESC'
  }
];

export function getSortOptions(productType?: ProductType): SortOption[] {
  const sortOptionConfigs =
    productType === 'FACULTY'
      ? commonSortOptions
      : [...commonSortOptions, ...creditsSortOptions];

  return map(
    sortOptionConfigs,
    (config) =>
      new SortOption(
        config.field === 'language'
          ? languageCodeToQueryName(i18n.language)
          : config.field,
        config.direction,
        t(config.translationKey)
      )
  );
}
