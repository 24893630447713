import { useTranslation } from 'react-i18next';
import { Compact } from '../../../components';
import Reference from '../../../references/Reference';
import Description, {
  getDescriptionText
} from '../../../types/canonical/Description';
import { getName } from '../../../types/Name';

type Props = {
  type?: Reference;
  descriptions: Description[];
  expanded?: boolean;
  className?: string;
};

export default function DescriptionItem({
  type,
  descriptions,
  expanded,
  className
}: Readonly<Props>) {
  const { t } = useTranslation('translation');

  const descriptionText = getDescriptionText(
    descriptions,
    t('NO_CURRENT_LANGUAGE_INFO')
  );

  return (
    <>
      {descriptionText ? (
        <div className={`details-main-section my-1 py-1 ${className}`}>
          <div className="d-flex flex-row">
            <h2 className="details-main-name d-flex flex-column flex-grow-1 mt-2">
              {getName(type)}
            </h2>
          </div>
          <div style={{ fontSize: '1.5rem' }}>
            <Compact expanded={expanded}>
              <div className="details-main-value">{descriptionText}</div>
            </Compact>
          </div>
        </div>
      ) : undefined}
    </>
  );
}
