import { isEmpty } from 'lodash';
import { ReferenceType } from '../../../references/Reference';
import { useReferences } from '../../../references/useReferences';
import Offering, {
  getPeriodsDisplayName
} from '../../../types/canonical/Offering';
import { WidgetField, WidgetType } from '../../Widget';
import { FieldSection } from './FieldSection';

type Props = {
  field: WidgetField;
  type: WidgetType;
  offerings: Offering[] | undefined;
  year: string;
};

export function PeriodsItem({ field, type, offerings, year }: Readonly<Props>) {
  const { references: blocks } = useReferences(ReferenceType.TIME_BLOCK, year);

  const periods = getPeriodsDisplayName(offerings, blocks);

  return (
    <FieldSection
      field={field}
      type={type}
      isEmpty={isEmpty(periods)}
    >
      {() => (
        <>
          {periods?.map((period, index) => (
            <div key={`period-${index}`}>{period}</div>
          ))}
        </>
      )}
    </FieldSection>
  );
}
