import { useEffect, useState } from 'react';
import { Url, urlBuilder, useQueryParams } from '@42.nl/react-url/lib';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Provider } from 'react-redux';
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';
import { AppFrame, Loader } from '../../components';
import { toQueryParams } from '../../components/AppFrame/components/TopBar/components/MainTabs/MainTabs';
import { useMainTabParams } from '../../components/AppFrame/components/TopBar/components/MainTabs/useMainTabParams';
import { LanguageLabel } from '../../i18n';
import SubTabsNav from '../../tabs/SubTabsNav';
import Tab from '../../tabs/Tab';
import { getTabService } from '../../tabs/TabService';
import AcademicYear from '../../types/AcademicYear';
import Group from '../../types/canonical/Group';
import { GROUP_APP_URL, toGroupsApp } from '../../types/GroupLinks';
import SimpleGroup from '../../types/SimpleGroup';
import { WidgetButtons } from '../../widgets/WidgetDetail/WidgetButtons';
import { WidgetDetail } from '../../widgets/WidgetDetail/WidgetDetail';
import YearDropdown from '../../years/components/YearDropdown/YearDropdown';
import { structureStore } from '../GroupStructure/structureStore';
import {
  GroupDetailPathParams,
  GroupDetailQueryParams,
  defaultGroupDetailQueryParams
} from './GroupDetailQueryParams';

async function loadGroup(code: string, queryParams: GroupDetailQueryParams) {
  return SimpleGroup.one(code, queryParams);
}

export default function GroupDetail() {
  const location = useLocation();
  const navigate = useNavigate();

  const mainTabParams = useMainTabParams();
  const [mainTab, setMainTab] = useState<Tab>();
  const [subTabs, setSubTabs] = useState<Tab[]>([]);
  const [activeTab, setActiveTab] = useState<Tab>();

  function toggle(tab: Tab) {
    const url = toGroupDetail(
      {
        code: `${state.data?.data.code}`
      },
      {
        year: `${state.data?.data.year.id}`,
        tab: `${tab.name}`,
        ...mainTabParams
      }
    );
    navigate(url, { replace: true });
    setActiveTab(tab);
  }

  const queryParams = useQueryParams<GroupDetailQueryParams>({
    location,
    defaultQueryParams: defaultGroupDetailQueryParams(),
    debugName: 'GroupDetail'
  });

  const { code = '' } = useParams<GroupDetailPathParams>();
  const { t } = useTranslation(['translation', 'group']);

  const state = useQuery(['group', { code, queryParams }], () =>
    loadGroup(code, queryParams)
  );

  useEffect(() => {
    if (state.data) {
      getTabService().getMainTab(queryParams.tab, state.data).then(setMainTab);
    }
  }, [queryParams.tab, state.data]);

  useEffect(() => {
    if (mainTab) {
      getTabService()
        .getSubTabs(mainTab)
        .then((tabs) => {
          setSubTabs(tabs);

          const tab = getTabService().getActive(tabs, queryParams.tab);
          setActiveTab(tab);
        });
    }
  }, [mainTab, queryParams.tab]);

  const [years, setYears] = useState<AcademicYear[]>();
  useEffect(() => {
    async function fetchYears() {
      const code_ = state?.data?.data?.code;
      if (code_) {
        const data = await SimpleGroup.years(code_);
        setYears(data);
      }
    }

    if (!years) {
      fetchYears();
    }
  }, [state.data, years]);

  function filterChanged(name: string, value: string | number) {
    navigate(toGroupDetail({ code }, { ...queryParams, [name]: value }), {
      replace: true
    });

    window.location.reload();
  }

  return (
    <Loader
      state={state}
      isEmpty={(data) => data?.id === undefined}
      emptyContent={() => (
        <Navigate
          to={toGroupsApp(mainTabParams)}
          replace
        />
      )}
    >
      {(group) => (
        <AppFrame
          breadcrumbs={
            <Breadcrumb>
              <BreadcrumbItem>
                <Link
                  to={toGroupsApp({
                    ...toQueryParams(mainTab, group.data.year.id),
                    year: queryParams.year
                  })}
                >
                  {t('group:GROUPS')}
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>{group.data.code}</BreadcrumbItem>
            </Breadcrumb>
          }
        >
          <Row className="justify-content-center">
            <Col
              md={12}
              lg={12}
              xs={12}
              className="details-main me-auto"
            >
              <Provider store={structureStore}>
                <h1 className="details-main-title">
                  <span className="pe-1">({group.data.code})</span>
                  <LanguageLabel names={group.data.names} />
                </h1>

                <Row className="mb-3">
                  <Col
                    m={12}
                    md={12}
                    lg={12}
                    xs={12}
                  >
                    <YearDropdown
                      externalId={queryParams.year}
                      initYears={years}
                      onChange={filterChanged}
                      className="d-inline"
                    />
                    <WidgetButtons product={group} />
                  </Col>
                </Row>

                <SubTabsNav
                  subTabs={subTabs}
                  detailItem={state.data}
                  toggle={toggle}
                  tab={activeTab?.name}
                />

                <TabContent activeTab={activeTab?.name}>
                  {subTabs.map((subTab) => (
                    <TabPane
                      tabId={subTab.name}
                      key={subTab.id}
                    >
                      <WidgetDetail
                        tabId={subTab.id}
                        product={group}
                        expanded={false}
                      />
                    </TabPane>
                  ))}
                </TabContent>
              </Provider>
            </Col>
          </Row>
        </AppFrame>
      )}
    </Loader>
  );
}

export const GROUP_DETAIL_URL = `${GROUP_APP_URL}/:code`;

function toGroupDetail(
  pathParams: GroupDetailPathParams,
  queryParams: GroupDetailQueryParams
): Url {
  return urlBuilder({
    url: GROUP_DETAIL_URL,
    pathParams: pathParams,
    queryParams: queryParams,
    defaultQueryParams: defaultGroupDetailQueryParams()
  });
}

export function groupDetailUrl({
  id,
  code,
  uid,
  year,
  mainTab
}: {
  mainTab?: string;
} & Group) {
  return toGroupDetail(
    {
      code: code || id || uid
    },
    {
      year: year.id,
      mainTab
    }
  );
}
