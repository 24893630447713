import { useTranslation } from 'react-i18next';
import { useMainTabParams } from '../../components/AppFrame/components/TopBar/components/MainTabs/useMainTabParams';
import { QuickviewItem } from '../../components/Quickview/components/QuickviewItem';
import { ProductQuickview } from '../../components/Quickview/Product/ProductQuickview';
import { ReferenceType } from '../../references/Reference';
import { useReferences } from '../../references/useReferences';
import { getCredits } from '../../types/canonical/Credits';
import { getPeriodsDisplayName } from '../../types/canonical/Offering';
import SimpleModule from '../../types/SimpleModule';
import { moduleDetailUrl } from '../ModuleDetail/ModuleDetail';

interface Props {
  module: SimpleModule;
}

export default function ModuleListItem({ module }: Readonly<Props>) {
  const { t } = useTranslation('module');
  const { mainTab } = useMainTabParams();

  const { references: timeBlocks } = useReferences(
    ReferenceType.TIME_BLOCK,
    module.data.year.id
  );
  const periods = getPeriodsDisplayName(module.data.offerings, timeBlocks).join(
    ', '
  );

  return (
    <ProductQuickview
      key={module.id}
      product={module}
      descriptionType="MODULE_DESCRIPTION"
      seeMoreText={t('module:SEE_MORE')}
      linkToDetail={moduleDetailUrl({ mainTab, ...module.data })}
      details={[
        <QuickviewItem
          key={`${module.id}+${module.data.code}`}
          displayName={t('module:COLUMNS.YEAR')}
          value={module.data.year.id || ''}
        />,
        <QuickviewItem
          key={`${module.id}+${module.data.type}`}
          displayName={t('module:COLUMNS.TYPE')}
          value={`${module.data.type || ''}`}
        />,
        <QuickviewItem
          key={`${module.id}-periods`}
          displayName={t('module:COLUMNS.MODULE.PERIOD')}
          value={periods}
        />
      ]}
    >
      <QuickviewItem
        displayName={t('module:SHORT.CREDITS')}
        value={getCredits(module.data.credits)}
      />
      <QuickviewItem
        displayName={t('module:COLUMNS.MODULE.PERIOD')}
        value={periods}
      />
      <QuickviewItem
        displayName={t('module:COLUMNS.YEAR')}
        value={module.data.year.id}
      />
    </ProductQuickview>
  );
}
