import { useEffect, useState } from 'react';
import { Url, urlBuilder, useQueryParams } from '@42.nl/react-url/lib';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';
import { AppFrame, Loader } from '../../components';
import { toQueryParams } from '../../components/AppFrame/components/TopBar/components/MainTabs/MainTabs';
import { useMainTabParams } from '../../components/AppFrame/components/TopBar/components/MainTabs/useMainTabParams';
import { PrintButton } from '../../components/Print/PrintButton';
import { LanguageLabel } from '../../i18n';
import { ProductPrint } from '../../product/ProductPrint';
import SubTabsNav from '../../tabs/SubTabsNav';
import Tab from '../../tabs/Tab';
import { getTabService } from '../../tabs/TabService';
import AcademicYear from '../../types/AcademicYear';
import Data from '../../types/canonical/Data';
import Faculty from '../../types/Faculty';
import { WidgetButtons } from '../../widgets/WidgetDetail/WidgetButtons';
import { WidgetDetail } from '../../widgets/WidgetDetail/WidgetDetail';
import YearDropdown from '../../years/components/YearDropdown/YearDropdown';
import { FACULTY_APP_URL, toFacultiesApp } from '../FacultyLinks';
import {
  defaultFacultyDetailQueryParams,
  FacultyDetailQueryParams
} from '../FacultyQueryParams';
import { FacultyDetailPathParams } from './FacultyDetailQueryParams';

export async function loadFaculty(
  code: string,
  queryParams: FacultyDetailQueryParams
) {
  return Faculty.one(code, queryParams);
}

export default function FacultyDetail() {
  const navigate = useNavigate();
  const location = useLocation();

  const mainTabParams = useMainTabParams();
  const [mainTab, setMainTab] = useState<Tab>();
  const [subTabs, setSubTabs] = useState<Tab[]>([]);
  const [activeTab, setActiveTab] = useState<Tab>();

  function toggle(tab: Tab) {
    const url = toFacultyDetail(
      {
        code: `${state.data?.data.code}`
      },
      {
        year: `${state.data?.data.year.id}`,
        tab: `${tab.name}`,
        ...mainTabParams
      }
    );

    navigate(url, { replace: true });
  }

  const queryParams = useQueryParams<FacultyDetailQueryParams>({
    location,
    defaultQueryParams: defaultFacultyDetailQueryParams(),
    debugName: 'FacultyDetail'
  });

  const { code = '' } = useParams<FacultyDetailPathParams>();
  const { t } = useTranslation(['translation', 'faculty']);

  const state = useQuery(['faculties', { code, queryParams }], () =>
    loadFaculty(code, queryParams)
  );

  useEffect(() => {
    if (state.data) {
      getTabService().getMainTab(queryParams.tab, state.data).then(setMainTab);
    }
  }, [queryParams.tab, state.data]);

  useEffect(() => {
    if (mainTab) {
      getTabService()
        .getSubTabs(mainTab)
        .then((tabs) => {
          setSubTabs(tabs);

          const tab = getTabService().getActive(tabs, queryParams.tab);
          setActiveTab(tab);
        });
    }
  }, [mainTab, queryParams.tab]);

  const [years, setYears] = useState<AcademicYear[]>();
  useEffect(() => {
    async function fetchYears() {
      if (state.data && state.data.data.code) {
        const data = await Faculty.years(state.data.data.code);
        setYears(data);
      }
    }

    if (!years) {
      fetchYears();
    }
  }, [state.data, years]);

  function filterChanged(name: string, value: string | number) {
    navigate(toFacultyDetail({ code }, { ...queryParams, [name]: value }), {
      replace: true
    });
    window.location.reload();
  }

  return (
    <Loader
      state={state}
      isEmpty={(data) => data?.id === undefined}
      emptyContent={() => (
        <Navigate
          to={toFacultiesApp(mainTabParams)}
          replace
        />
      )}
    >
      {(faculty) => (
        <AppFrame
          breadcrumbs={
            <Breadcrumb>
              <BreadcrumbItem>
                <Link
                  to={toFacultiesApp({
                    ...toQueryParams(mainTab, faculty.data.year.id),
                    year: queryParams.year
                  })}
                >
                  {t('faculty:FACULTIES')}
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>{faculty.data.code}</BreadcrumbItem>
            </Breadcrumb>
          }
        >
          <Row className="justify-content-center">
            <Col
              md={12}
              lg={12}
              xs={12}
              className="details-main me-auto"
            >
              <h1 className="details-main-title">
                <span className="pe-1">({faculty.data.code})</span>
                <LanguageLabel names={faculty.data.names} />
              </h1>

              <Row>
                <Col
                  m={12}
                  md={12}
                  lg={12}
                  xs={12}
                >
                  <YearDropdown
                    externalId={queryParams.year}
                    initYears={years}
                    className="mb-4 d-inline"
                    onChange={filterChanged}
                  />
                  <WidgetButtons product={faculty}>
                    <PrintButton>
                      <ProductPrint
                        tabs={subTabs}
                        product={faculty}
                      />
                    </PrintButton>
                  </WidgetButtons>
                </Col>
              </Row>

              <SubTabsNav
                subTabs={subTabs}
                detailItem={state.data}
                toggle={toggle}
                tab={activeTab?.name}
              />

              <TabContent activeTab={activeTab?.name}>
                {subTabs.map((subTab) => (
                  <TabPane
                    tabId={subTab.name}
                    key={subTab.id}
                  >
                    <WidgetDetail
                      tabId={subTab.id}
                      product={faculty}
                      expanded={true}
                    />
                  </TabPane>
                ))}
              </TabContent>
            </Col>
          </Row>
        </AppFrame>
      )}
    </Loader>
  );
}

export const FACULTY_DETAIL_URL = `${FACULTY_APP_URL}/:code`;

function toFacultyDetail(
  pathParams: FacultyDetailPathParams,
  queryParams: FacultyDetailQueryParams
): Url {
  return urlBuilder({
    url: FACULTY_DETAIL_URL,
    pathParams: pathParams,
    queryParams: queryParams
  });
}

export function facultyDetailUrl({
  id,
  uid,
  code,
  year,
  mainTab
}: {
  mainTab?: string;
} & Data) {
  return toFacultyDetail(
    {
      code: code || id || uid
    },
    {
      year: year.id,
      mainTab
    }
  );
}
