import { MainTabParams } from '../../components/AppFrame/components/TopBar/components/MainTabs/MainTabs';
import { getYearService } from '../../years/YearService';

export type QualificationDetailPathParams = {
  code: string;
};

export type QualificationDetailQueryParams = {
  year: string;
  tab?: string;
} & MainTabParams;

export function defaultQualificationDetailQueryParams(): QualificationDetailQueryParams {
  return {
    year: getYearService().getCurrentYear().externalId,
    tab: 'info',
    mainTab: 'qualification'
  };
}
