import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import Loader from '../Loader/Loader';

type Props = {
  children: () => React.ReactNode;
};

type Customer = {
  name: string;
  style: string;
};

async function getCustomer(): Promise<Customer> {
  const response = await fetch('/api/customer');
  return await response.json();
}

export function CustomerStyle({ children }: Readonly<Props>) {
  const { i18n } = useTranslation();
  const state = useQuery(['customer', {}], () => getCustomer());

  return (
    <Loader state={state}>
      {(customer) => (
        <div className={`customer-body customer-body-lang-${i18n.language}`}>
          <style>{customer.style}</style>
          {children()}
        </div>
      )}
    </Loader>
  );
}
