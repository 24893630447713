import { MainTabParams } from '../components/AppFrame/components/TopBar/components/MainTabs/MainTabs';
import { getYearService } from '../years/YearService';

export type FacultyDetailQueryParams = {
  year: string;
  tab?: string;
  code?: string[];
} & MainTabParams;

export function defaultFacultyDetailQueryParams(): FacultyDetailQueryParams {
  return {
    year: getYearService().getCurrentYear().externalId,
    mainTab: 'faculty',
    code: []
  };
}
