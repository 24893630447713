/* istanbul ignore file */

import { authFetch } from '@42.nl/authentication';

// Send the error to the back-end.
async function sendError(message?: string, error?: any) {
  // Gets the complete url including the query params.
  const url = window.location.href;

  // Contains OS and browser version info
  const userAgent = window.navigator.userAgent;
  const stack = error && error.stack ? error.stack : 'no stack available';
  const data = { message, url, stack, userAgent };

  try {
    await authFetch('/api/log/error', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify(data)
    });
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.log('Failed to log error to backend: ' + ex);
  }
}

export default function handleErrors(): void {
  /*
    Not all browsers handle onerror the same:

    https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror

    Stack traces differ per browser.
  */
  window.onerror = function (message, file, line, col, error) {
    sendError(message as string, error);
  };

  /*
    Only works in chrome at the moment:

    https://developer.mozilla.org/en-US/docs/Web/Events/unhandledrejection

    But something is better than nothing.
  */
  window.addEventListener('unhandledrejection', async (event) => {
    const message = getMessageForPromiseRejectionEvent(event);

    sendError(message);
  });
}

function getMessageForPromiseRejectionEvent(
  event: PromiseRejectionEvent
): string {
  if (event && event.reason) {
    const reason = event.reason;
    if (reason.status && reason.url) {
      return `UnhandledPromiseRejection: ${reason.url}: returned HTTP status: ${reason.status}`;
    } else if (reason.message) {
      return `UnhandledPromiseRejection: ${reason.message};`;
    }
  }

  return 'UnhandledPromiseRejection: no further information';
}
