import React, { useState } from 'react';
import classNames from 'classnames';
import i18n from '../../../../core/i18n';
import DescriptionQuickView from '../../../i18n/DescriptionQuickView/DescriptionQuickView';
import { getText } from '../../../i18n/Text';
import Product from '../../../types/Product';
import { Expander } from '../../index';
import { QuickviewDetailsLink } from '../components/QuickviewDetailsLink';
import { QuickviewDetailsSection } from '../components/QuickviewDetailsSection';

type Props = {
  product: Product;
  descriptionType: string;
  seeMoreText: string;
  linkToDetail: string;
  details: React.ReactNode[];
  children?: React.ReactNode;
  readOnly?: boolean;
};

export function ProductQuickview({
  product,
  descriptionType,
  seeMoreText,
  linkToDetail,
  details,
  children,
  readOnly
}: Readonly<Props>) {
  const [collapsed, setCollapsed] = useState(true);

  const language = i18n.language.toUpperCase();
  const name = getText(product.data.names);

  const toggle = () => {
    if (!readOnly) {
      setCollapsed(!collapsed);
    }
  };

  return (
    <section
      className={classNames('quickview quickview-product d-flex flex-column', {
        'cursor-default': readOnly
      })}
      onClick={toggle}
    >
      <div className="quickview-main d-flex flex-row align-items-center">
        <div className="d-flex flex-column flex-grow-1 pe-md-4">
          <div>
            <span className="element-name pe-1 font-weight-normal">
              ({product.data.code})
            </span>
            <span className="element-name">{name}</span>
          </div>
          <div className="d-flex mt-2">{children}</div>
        </div>
        {!readOnly ? <Expander collapsed={collapsed} /> : null}
      </div>
      {
        // Note: the fontSize (and possibly other styles) need to be set inline
        // to prevent re-renders and other weird bugs
      }
      {!collapsed ? (
        <div
          style={{ fontSize: '1.5rem' }}
          className="quickview-extra pt-3 pb-2 mt-2"
        >
          <div className="quickview-extra-summary flex-grow-1 d-flex flex-column pe-0 pe-md-4">
            <div className="flex-grow-1 pb-2 pb-sm-0">
              <DescriptionQuickView
                descriptions={product.data?.descriptions || []}
                type={descriptionType}
                year={product.data.year.id}
                language={language}
              />
            </div>
            <QuickviewDetailsLink
              linkToDetail={linkToDetail}
              seeMoreText={seeMoreText}
            />
          </div>
          <QuickviewDetailsSection details={details} />
        </div>
      ) : null}
    </section>
  );
}
