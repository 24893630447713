import { first, transform } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Compact, Loader } from '../../components';
import Reference from '../../references/Reference';
import { getReferenceService } from '../../references/ReferenceService';
import Description, { render } from '../../types/canonical/Description';

type Props = {
  type: string;
  year: string;
  language: string;
  descriptions: Description[];
};

export default function DescriptionQuickView({
  type,
  year,
  language,
  descriptions
}: Readonly<Props>) {
  const { t } = useTranslation('translation');
  const fallback = t('NO_INFO_FOUND');

  const state = useQuery(['getTypes', { type, year }], () =>
    getTypes(type, year)
  );

  return (
    <Loader state={state}>
      {(types) => (
        <Compact expandable={false}>
          <div className="details-main-value">
            {getSummary(types || [], language, descriptions, fallback)}
          </div>
        </Compact>
      )}
    </Loader>
  );
}

async function getTypes(type: string, year: string) {
  const references = await getReferenceService().getActiveReferences(
    type,
    year
  );

  return references.filter((type_) => type_.visible && !!type_.externalId);
}

function getSummary(
  types: Reference[],
  language: string,
  descriptions: Description[],
  fallback: string
) {
  const description = findDescription(descriptions, types, language);
  return description ? render(description) : <i>{fallback}</i>;
}

function findDescription(
  descriptions: Description[] | undefined,
  types: Reference[],
  language: string
) {
  if (!descriptions) {
    return '';
  }

  const texts = transform(
    types,
    (result, type) => {
      const filtered = descriptions.filter(
        (d) => d.type?.id === type.externalId
      );
      const text = filtered.find((d) => d.language === language)?.text;
      if (text) {
        result.push(text);
      }
      const alternative = filtered.find((d) => d.language !== language)?.text;
      if (alternative) {
        result.push(alternative);
      }
    },
    new Array<string>()
  );

  return first(texts);
}
