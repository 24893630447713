import { Page } from '@42.nl/spring-connect';
import { Pagination } from '@42.nl/ui';
import { Col, Row } from 'reactstrap';
import { PageSize } from './PageSize/PageSize';

interface PaginationBarProps<T> {
  page: Page<T>;
  onChange: (page: { page: number; size: number }) => void;
}

export function PaginationBar<T>({ page, onChange }: PaginationBarProps<T>) {
  function onSize(size: number) {
    onChange({
      page: page.number,
      size
    });
  }

  function onPage(number: number) {
    onChange({
      page: number,
      size: page.size
    });
  }

  return (
    <Row className="pt-3 align-items-center">
      <Col
        sm="6"
        className="d-none d-md-block"
      >
        <PageSize
          value={page.size}
          onChange={(size) => onSize(size)}
        />
      </Col>
      <Col
        xs={12}
        md={6}
        className="d-flex justify-content-center justify-content-md-end"
      >
        <Pagination
          page={page}
          className="float-end"
          onChange={(number) => onPage(number)}
        />
      </Col>
    </Row>
  );
}
