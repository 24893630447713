import { InfoTooltip } from '@42.nl/ui';
import { useTranslation } from 'react-i18next';

type Props = {
  displayName?: string;
  tooltip?: string;
  value: string | number | bigint | boolean | undefined;
  className?: string;
};

export function QuickviewItem({
  displayName,
  tooltip,
  value,
  className
}: Readonly<Props>) {
  const { t } = useTranslation(['translation']);

  let displayValue: string = t('translation:NO_DATA');
  let isBoolean = false;

  if (value !== undefined) {
    if (typeof value === 'string' && value !== '') {
      displayValue = value;
    } else if (typeof value === 'number') {
      displayValue = String(value);
    } else if (typeof value === 'boolean') {
      isBoolean = true;
    }
  }

  return (
    <>
      {isBoolean ? null : (
        <div className={`quickview-item mb-2 ${className ?? ''}`}>
          {displayName ? (
            <span className="quickview-extra-label me-1">{displayName}:</span>
          ) : null}
          <span className="quickview-extra-value">{displayValue}</span>
          {tooltip ? (
            <InfoTooltip
              tooltip={tooltip}
              className="ms-1 text-primary"
              size={15}
            />
          ) : null}
        </div>
      )}
    </>
  );
}
