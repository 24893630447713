import i18n from '../../core/i18n';

export type Text = {
  language?: string;
  value?: string;
};

export function getText(texts?: Text[], fallback?: string) {
  if (texts === undefined) {
    return fallback;
  }

  const language = i18n.language.toUpperCase();
  let value = texts.find((text) => text.language === language)?.value;
  if (!value) {
    value =
      fallback || texts.map((text) => text.value).find((value_) => !!value_);
  }
  return value;
}
