import classnames from 'classnames';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { getText } from '../i18n/Text';
import Product from '../types/Product';
import Tab from './Tab';

type Props = {
  subTabs: Tab[];
  detailItem: Product | undefined;
  toggle: (tab: Tab) => void;
  tab: string | undefined;
};

export default function SubTabsNav({
  subTabs,
  detailItem,
  toggle,
  tab
}: Readonly<Props>) {
  if (!(subTabs.length > 1)) {
    return <div className="mb-3"></div>;
  }

  return (
    <Nav
      tabs
      className="mt-4 mb-2"
    >
      {detailItem &&
        subTabs?.map((subTab) => (
          <NavItem
            id={subTab.id.toString()}
            key={subTab.id}
          >
            <NavLink
              className={classnames({
                active: tab === subTab.name
              })}
              onClick={() => toggle(subTab)}
            >
              {getText(subTab.labels, subTab.name)}
            </NavLink>
          </NavItem>
        ))}
    </Nav>
  );
}
