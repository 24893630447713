import { get, makeResource } from '@42.nl/spring-connect';
import { QueryParams, search } from '../filters/Search';
import AcademicYear from './AcademicYear';
import Data from './canonical/Data';
import { ProductTypeEnum } from './Product';

const baseUrl = '/api/faculty';

export default class Faculty extends makeResource<Faculty>(baseUrl) {
  id!: number;
  data!: Data;
  productType!: string;

  static async search(queryParams: QueryParams) {
    return search<Faculty>(ProductTypeEnum.FACULTY, baseUrl, queryParams);
  }

  static years(code: string): Promise<AcademicYear[]> {
    return get(`${baseUrl}/${code}/years`);
  }
}
