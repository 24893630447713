import { MainTabParams } from '../../components/AppFrame/components/TopBar/components/MainTabs/MainTabs';
import { getYearService } from '../../years/YearService';

export type StudyDetailPathParams = {
  code: string;
};

export type StudyDetailQueryParams = {
  year: string;
  mainTab?: string;
  tab?: string;
} & MainTabParams;

export function defaultStudyDetailQueryParams(): StudyDetailQueryParams {
  return {
    year: getYearService().getCurrentYear().externalId,
    tab: 'info'
  };
}
