import { makeResource } from '@42.nl/spring-connect';
import { Text } from '../i18n/Text';

const baseUrl = '/api/filters';

export default class Filter extends makeResource<Filter>(baseUrl) {
  id!: number;
  entityType!: string;
  name!: string;
  values?: string;
  exact!: boolean;
  visible!: boolean;
  sequence?: number;
  valueType!: string;
  valueSubType?: string;
  displayType!: string;
  maxDisplayed?: number;
  labels!: Text[];

  constructor() {
    super();

    this.visible = true;
    this.displayType = FilterDisplayType.SHOW_ALL;
  }
}

export enum FilterDisplayType {
  SHOW_ALL = 'SHOW_ALL',
  SHOW_MORE = 'SHOW_MORE',
  POPUP = 'POPUP'
}

export function getNumbers(filter: Filter, defaultValues: number[]) {
  if (filter.values) {
    return filter.values
      .split(',')
      .map((val) => val.trim())
      .map((val) => parseFloat(val))
      .filter((val) => !isNaN(val));
  }

  return defaultValues;
}
