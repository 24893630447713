import _ from 'lodash';
import { useQuery } from 'react-query';
import { Col, Row } from 'reactstrap';
import Loader from '../../components/Loader/Loader';
import Product from '../../types/Product';
import Widget from '../Widget';
import { WidgetPanel } from '../WidgetPanel/WidgetPanel';

type Props = {
  tabId: number;
  product: Product;
  expanded: boolean;
  includeWidget?: (widget: Widget) => boolean;
};

async function loadWidgets(tabId: number) {
  const widgets = await Widget.getWidgetsForTab(tabId);
  return _(widgets).filter('visible').sortBy(['sequence', 'id']).value();
}

export function WidgetDetail({
  tabId,
  product,
  expanded,
  includeWidget = () => true
}: Readonly<Props>) {
  const state = useQuery(['widgets', { tabId }], () => loadWidgets(tabId));

  return (
    <Loader state={state}>
      {(widgets) => (
        <WidgetContainer
          widgets={widgets.filter(includeWidget)}
          product={product}
          expanded={expanded}
        />
      )}
    </Loader>
  );
}

function WidgetContainer({
  widgets,
  product,
  expanded
}: {
  widgets: Widget[];
  product: Product;
  expanded: boolean;
}) {
  const sidebar = widgets.some((widget) => widget.type === 'SIDEBAR');
  const size = sidebar ? 7 : 12;

  return (
    <Row className="details-content-container">
      <Col
        md={size}
        lg={size}
        xs={12}
        className="details-main me-auto"
      >
        {widgets
          .filter((widget) => widget.type !== 'SIDEBAR')
          .map((widget) => (
            <WidgetPanel
              key={widget.id}
              widget={widget}
              product={product}
              expanded={expanded}
            />
          ))}
      </Col>
      {sidebar ? (
        <Col
          md={2}
          lg={2}
          xs={12}
          className="details-extra"
        >
          {widgets
            .filter((widget) => widget.type === 'SIDEBAR')
            .map((widget) => (
              <WidgetPanel
                key={widget.id}
                widget={widget}
                product={product}
                expanded={expanded}
              />
            ))}
        </Col>
      ) : null}
    </Row>
  );
}
