import { get } from 'lodash';
import { replaceVariables } from '../../../i18n/Interpolation';
import { getText, Text } from '../../../i18n/Text';
import Reference from '../../../references/Reference';
import { useReferences } from '../../../references/useReferences';
import { getCredits } from '../../../types/canonical/Credits';
import Data from '../../../types/canonical/Data';
import Study from '../../../types/canonical/Study';
import { translate, WidgetField, WidgetType } from '../../Widget';
import { FieldSection } from './FieldSection';

type Props = {
  field: WidgetField;
  type: WidgetType;
  data: Data;
};

function isEmpty(value: string | number | undefined | null) {
  return value === null || value === undefined || value === '';
}

export function ValuesItem({ field, type, data }: Readonly<Props>) {
  const { references } = useReferences(field.referenceType, data.year.id);
  const value = getValue(field, data, references);

  return (
    <FieldSection
      field={field}
      type={type}
      isEmpty={isEmpty(value)}
    >
      {() => <div>{value}</div>}
    </FieldSection>
  );
}

function getValue(field: WidgetField, data: Data, references: Reference[]) {
  const { name } = field;
  if (!name) {
    return '';
  }

  if (name === 'code') {
    return data.code;
  } else if (name === 'name') {
    return getText(data.names);
  } else if (name === 'credits') {
    return `${getCredits(data.credits)}`;
  } else if (name === 'year') {
    return getName(data.year, field);
  } else if (name === 'faculty') {
    return getName(data.faculty, field);
  } else if (name === 'organisation') {
    return getName(data.organisation, field);
  } else if (name === 'specification' && data instanceof Study) {
    const study = data as Study;
    return getName(study.specification, field);
  }

  const value = get(data, name);
  if (value !== undefined) {
    return translate(field, [value], references);
  }
  return '';
}

function getName(context: Context | undefined, field: WidgetField) {
  if (!context) {
    return '';
  }

  const name = getText(context.names, context.code) || '';
  if (!field.format) {
    return name;
  }

  return replaceVariables(field.format, {
    code: context.code,
    name
  });
}

type Context = {
  code: string;
  names?: Text[];
};
