import { useClearErrors } from '@42.nl/react-error-store';
import { getEnumsAsPage, useEnum } from '@42.nl/react-spring-enums';
import {
  AddonIcon,
  Button,
  JarbDateTimeInput,
  JarbInput,
  JarbValuePicker,
  SubmitButton
} from '@42.nl/ui';
import { capitalize } from 'lodash';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import {
  isEmail,
  isEmailUnique
} from '../../../../../core/validation/validation';

export type UserFormData = {
  id?: number;
  email?: string;
  endDate?: Date;
  roles: string[];
};

type Props = {
  onCancel: () => void;
  onSubmit: (userForm: UserFormData) => void;
  initialValues?: UserFormData;
};

const emailValidators = [isEmail];
const asyncEmailValidators = [isEmailUnique];

export default function UserForm(props: Readonly<Props>) {
  const { onCancel, onSubmit, initialValues } = props;
  const userRoles = useEnum('UserRole');
  const { t } = useTranslation('user');

  // Clear previous back-end errors if this form was shown before
  useClearErrors();

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <JarbInput
            id="name"
            name="name"
            jarb={{
              validator: 'User.name',
              label: t('USER.FORM.NAME')
            }}
            label={t('USER.FORM.NAME')}
          />

          <JarbInput
            id="externalId"
            name="externalId"
            jarb={{
              validator: 'User.externalId',
              label: t('USER.FORM.EXTERNAL_ID')
            }}
            label={t('USER.FORM.EXTERNAL_ID')}
          />

          <JarbInput
            id="email"
            name="email"
            jarb={{
              validator: 'User.email',
              label: t('USER.FORM.EMAIL')
            }}
            validators={emailValidators}
            asyncValidators={asyncEmailValidators}
            asyncValidatorsDebounce={1000}
            label={t('USER.FORM.EMAIL')}
            placeholder={t('USER.FORM.EMAIL_PLACEHOLDER')}
            addon={<AddonIcon icon="mail" />}
          />

          <JarbValuePicker
            multiple={true}
            name="roles"
            jarb={{
              validator: 'User.roles',
              label: t('USER.FORM.ROLES')
            }}
            id="roles"
            label={t('USER.FORM.ROLES')}
            placeholder={t('USER.FORM.ROLES_PLACEHOLDER')}
            canSearch={true}
            options={({ query, page }) => {
              return getEnumsAsPage({
                enumValues: userRoles,
                query,
                page
              });
            }}
            labelForOption={capitalize}
          />

          <JarbDateTimeInput
            id="endDate"
            name="endDate"
            label={t('USER.FORM.END_DATE')}
            dateFormat="DD-MM-YYYY"
            timeFormat={false}
            jarb={{
              validator: 'User.endDate',
              label: t('USER.FORM.END_DATE')
            }}
            addon={<AddonIcon icon="calendar_view_day" />}
          />

          <div className="mb-2">
            <SubmitButton
              inProgress={submitting}
              className="me-2"
            >
              Submit
            </SubmitButton>
            <Button onClick={onCancel}>Cancel</Button>
          </div>
        </form>
      )}
    />
  );
}
