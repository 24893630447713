import { urlBuilder } from '@42.nl/react-url';
import { get, makeResource, post } from '@42.nl/spring-connect';
import { isEmpty } from 'lodash';
import AcademicYear from '../types/AcademicYear';
import { getName } from '../types/Name';
import { ProductType } from '../types/Product';

const baseUrl = '/api/references';

export default class Reference extends makeResource<Reference>(baseUrl) {
  id!: number;
  externalId!: string;
  code!: string;
  dutchName?: string;
  englishName?: string;
  type!: string;
  visible!: boolean;
  manual!: boolean;
  sequence?: number;
  startDate?: Date;
  endDate?: Date;
  year?: AcademicYear;

  static async import(year: string) {
    const url = urlBuilder({
      url: baseUrl + '/import',
      queryParams: { year }
    });
    return post(url, null);
  }

  static async findByType(type: string): Promise<Reference[]> {
    const url = urlBuilder({
      url: `${baseUrl}/${type}`
    });
    return get(url);
  }
}

export enum ReferenceType {
  FACULTY_DESCRIPTION = 'FACULTY_DESCRIPTION',
  QUALIFICATION_DESCRIPTION = 'QUALIFICATION_DESCRIPTION',
  SPECIFICATION_DESCRIPTION = 'SPECIFICATION_DESCRIPTION',
  STUDY_DESCRIPTION = 'STUDY_DESCRIPTION',
  MODULEGROUP_DESCRIPTION = 'MODULEGROUP_DESCRIPTION',
  MODULE_DESCRIPTION = 'MODULE_DESCRIPTION',
  GROUP_TYPE = 'GROUP_TYPE',
  METHOD_TYPE = 'METHOD_TYPE',
  LINK = 'LINK',
  PHASE = 'PHASE',
  SOFTWARE = 'SOFTWARE',
  TIME_BLOCK = 'TIME-BLOCK'
}

export function displayName(
  externalId: string | undefined,
  references: Reference[],
  defaultValue?: string
) {
  if (isEmpty(externalId)) {
    return;
  }

  const reference = references.find((ref) => ref.externalId === externalId);
  let name;
  if (reference) {
    name = getName(reference);
  }
  return isEmpty(name) ? defaultValue || externalId : name;
}

export function getDescriptionType(productType: ProductType) {
  switch (productType) {
    case 'FACULTY':
      return ReferenceType.FACULTY_DESCRIPTION;
    case 'QUALIFICATION':
      return ReferenceType.QUALIFICATION_DESCRIPTION;
    case 'SPECIFICATION':
      return ReferenceType.SPECIFICATION_DESCRIPTION;
    case 'STUDY':
      return ReferenceType.STUDY_DESCRIPTION;
    case 'GROUP':
      return ReferenceType.MODULEGROUP_DESCRIPTION;
    case 'MODULE':
      return ReferenceType.MODULE_DESCRIPTION;
    default:
      throw new Error('Unknown entity type');
  }
}
