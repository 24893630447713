import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';
import { ProductType } from '../../types/Product';
import { getSort } from '../Sort/Sort';
import { getSortOptions, SortOption } from './SortOption';

type Props = {
  productType?: ProductType;
  sortParam: string;
  onChange: (option: SortOption) => void;
};

export default function SortSelector({
  productType,
  sortParam,
  onChange
}: Readonly<Props>) {
  const { t } = useTranslation('translation');

  const [sortDropdownOpen, setDropdownOpen] = useState(false);
  const [currentSort, setCurrentSort] = useState<SortOption>();

  useEffect(() => {
    setCurrentSort(parseSort(sortParam));
  }, [sortParam]);

  function toggle() {
    setDropdownOpen(!sortDropdownOpen);
  }

  return (
    <Dropdown
      nav
      isOpen={sortDropdownOpen}
      toggle={toggle}
      className="sort-selector d-none d-md-flex ms-auto"
      direction="down"
    >
      <DropdownToggle
        nav
        caret
        className="pe-0"
      >
        <span className="pe-2">{t('translation:SORT')}:</span>
        <span>{currentSort?.displayName}</span>
      </DropdownToggle>

      <DropdownMenu>
        {getSortOptions(productType).map((option) => (
          <DropdownItem
            key={option.sort}
            className={classNames('clickable', {
              active: option.sort === currentSort?.sort
            })}
            tag="div"
            color="link"
            onClick={() => {
              setCurrentSort(option);
              onChange(option);
            }}
          >
            {option.displayName}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}

export function getDefaultSort() {
  return getSortOptions()[0];
}

export function parseSort(sortParam: string) {
  const sort = getSort(sortParam);
  return getSortOptions().filter(
    (option) =>
      option.column === sort.column && option.direction === sort.direction
  )[0];
}
