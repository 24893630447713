import { isEmpty } from 'lodash';

type Props = {
  breadcrumbs?: React.ReactNode;
  children?: React.ReactNode;
  title?: string;
};

export default function Main(props: Readonly<Props>) {
  const { breadcrumbs, children, title } = props;

  return (
    <>
      <div className="AppFrame d-flex flex-column">
        {!isEmpty(title) ? <h1 className="my-4">{title}</h1> : null}
        {breadcrumbs}
        {children}
      </div>
    </>
  );
}
