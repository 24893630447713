import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Compact } from '../../../components';
import { getDescriptionType } from '../../../references/Reference';
import { useReferences } from '../../../references/useReferences';
import { getDescriptionText } from '../../../types/canonical/Description';
import Product from '../../../types/Product';
import { WidgetField, WidgetType } from '../../Widget';
import { FieldSection } from './FieldSection';

type Props = {
  field: WidgetField;
  type: WidgetType;
  product: Product;
  expanded: boolean;
};

export function DescriptionItem({
  field,
  type,
  product,
  expanded
}: Readonly<Props>) {
  const { t } = useTranslation('translation');
  const { references } = useReferences(
    getDescriptionType(product.productType),
    product.data.year.id
  );

  const descriptions = product.data.descriptions || [];
  const found = descriptions.filter(
    (description) => description.type?.id === field.name
  );

  const valid = found.filter((description) =>
    references.find((ref) => ref.externalId === description.type?.id)
  );

  if (isEmpty(valid)) {
    return null;
  }

  const descriptionText = getDescriptionText(
    found,
    t('NO_CURRENT_LANGUAGE_INFO')
  );

  return (
    <FieldSection
      field={field}
      type={type}
      isEmpty={isEmpty(descriptionText)}
    >
      {() => (
        <div style={{ fontSize: '1.5rem' }}>
          <Compact expanded={expanded}>
            <div className="details-main-value">{descriptionText}</div>
          </Compact>
        </div>
      )}
    </FieldSection>
  );
}
