import { isEmpty } from 'lodash';
import { getText } from '../../../i18n/Text';
import Data from '../../../types/canonical/Data';
import { getTemplate } from '../../../types/Product';
import { WidgetField } from '../../Widget';

type Props = {
  field: WidgetField;
  data: Data;
};

export function UrlItem({ field, data }: Readonly<Props>) {
  const url = getTemplate(field.name, data);
  if (isEmpty(url) && field.hideEmpty) {
    return null;
  }

  return (
    <section className="details-content-section d-flex py-3">
      <a
        className="text-decoration-none"
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        <span>{getText(field.labels, url)}</span>
      </a>
    </section>
  );
}
