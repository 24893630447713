import React from 'react';

type DetailsSectionProps = {
  details?: React.ReactNode[];
};

export const QuickviewDetailsSection = ({
  details
}: Readonly<DetailsSectionProps>) => {
  if (details) {
    return (
      <div className="quickview-extra-details ms-md-4">
        {details.map((detail, index) => (
          <div key={index}>{detail}</div>
        ))}
      </div>
    );
  }
  return null;
};
